























































import ImportStrategyTs from './ImportStrategyTs';
export default class ImportStrategy extends ImportStrategyTs {}
